import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import PropTypes from 'prop-types'
import Dolphin from '../../../../sea-site-theme/src/svg/dolphin'
import { BLOCKS, Block, Inline, Text } from '@contentful/rich-text-types'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { Triangle } from 'react-feather'
import Mline from '../../../../sea-site-theme/src/svg/m-line'
import Sline from '../../../../sea-site-theme/src/svg/s-line'
import Xline from '../../../../sea-site-theme/src/svg/x-line'
import Wave from '../../../../sea-site-theme/src/svg/wave'
// import Swash from '../../svg/swash-line'
// import Liberty from '../../svg/liberty-line'
import {
	DolphinBanner,
	DolphinBannerContainer,
	BannerTextHolder,
	FullSectionCol,
	DolphinSeries,
	BannerText,
	SeriesItemsContainer,
	NoItems,
	ItemContainer,
	SeriesItemImage,
	ItemLink,
	ItemTtl,
	CompareModels,
} from './styles'
import { BannerData } from '../../../../sea-site-theme/src/templates/product'
interface SeriesProps {
	seriesPage: {
		backgroundImage: {
			fluid: {
				src: string
				srcWebp: string
			}
		}
		banner: {
			backgroundImage: {
				fluid: {
					src: string
					srcWebp: string
				}
			}
			description: {
				raw: string
			}
		}
		media: {
			file: {
				url: string
			}
		} | null
		seoDescription: string
		slug: null
	}
	brandPage: {
		slug: string
	}
	slug: string
	series: string
	xlarge: BannerData
	large: BannerData
	medium: BannerData
	small: BannerData
	xsmall: BannerData
	xxsmall: BannerData
}

interface Product {
	node: {
		brand: string
		categories: {
			slug: string
			title: string
		}
		images: {
			description: string
			fluid: {
				src: string
				srcWebp: string
			}
		}[]
		model: string
		series: string
		slug: string
	}
}

const SeriesItems = graphql`
	query SeriesItemsQuery {
		seriesData: allContentfulProduct(filter: { countryAvailability: { eq: "MY" } }) {
			edges {
				node {
					series
					model
					slug
					node_locale
					brand
					categories {
						slug
					}
					images {
						fluid(quality: 60, maxWidth: 300, maxHeight: 300) {
							src
							srcWebp
						}
					}
				}
			}
		}
	}
`

const Series = ({
	series,
	seriesPage,
	brandPage,
	xlarge,
	large,
	medium,
	small,
	xsmall,
	xxsmall,
	slug,
}: SeriesProps): JSX.Element => {
	const SeriesItemsQuery = useStaticQuery(SeriesItems)
	const {
		seriesData: { edges: edgeArray },
	} = SeriesItemsQuery

	const products = edgeArray
		.filter((product: Product) => product.node.series === series && product.node.slug != slug)
		.filter((prod: Product) => (prod.node.categories === null ? prod : prod.node.categories?.slug != 'spare-parts'))

	const sources = seriesPage.backgroundImage
		? [
				{
					...seriesPage.backgroundImage.fluid,
					srcSet: `${seriesPage.backgroundImage.fluid.src} 1920w`,
					srcSetWebp: `${seriesPage.backgroundImage.fluid.srcWebp} 1920w`,
					media: `(min-width: 1601px)`,
				},
				{
					...xlarge.backgroundImage.fluid,
					srcSet: `${xlarge.backgroundImage.fluid.src} 1699w`,
					srcSetWebp: `${xlarge.backgroundImage.fluid.srcWebp} 1024w`,
					media: `(min-width: 1400px) and (max-width: 1699px)`,
				},
				{
					...large.backgroundImage.fluid,
					srcSet: `${large.backgroundImage.fluid.src} 1399w`,
					srcSetWebp: `${large.backgroundImage.fluid.srcWebp} 1399w`,
					media: `(min-width: 1280px) and (max-width: 1399px)`,
				},
				{
					...medium.backgroundImage.fluid,
					srcSet: `${medium.backgroundImage.fluid.src} 1279w`,
					srcSetWebp: `${medium.backgroundImage.fluid.srcWebp} 1279w`,
					media: `(min-width: 1024px) and (max-width: 1279px)`,
				},
				{
					...small.backgroundImage.fluid,
					srcSet: `${small.backgroundImage.fluid.src} 1023w`,
					srcSetWebp: `${small.backgroundImage.fluid.srcWebp} 1023w`,
					media: `(min-width: 768px) and (max-width: 1023px)`,
				},
				{
					...xsmall.backgroundImage.fluid,
					srcSet: `${xsmall.backgroundImage.fluid.src} 767w`,
					srcSetWebp: `${xsmall.backgroundImage.fluid.srcWebp} 767w`,
					media: `(min-width: 525px) and (max-width: 767px)`,
				},
				{
					...xxsmall.backgroundImage.fluid,
					srcSet: `${xxsmall.backgroundImage.fluid.src} 524w`,
					srcSetWebp: `${xxsmall.backgroundImage.fluid.srcWebp} 524w`,
					media: `(max-width: 524px)`,
				},
		  ]
		: []
	const options = {
		renderNode: {
			[BLOCKS.HEADING_1]: function Heading1() {
				return <></>
			},
			[BLOCKS.PARAGRAPH]: function Paragraph(
				node: Block | Inline | Text,
				children: React.ReactChild | undefined | null | React.ReactFragment
			) {
				return <BannerText>{children}</BannerText>
			},
		},
	}
	return series ? (
		<FullSectionCol>
			<DolphinBanner>
				<DolphinBannerContainer sources={sources}>
					{brandPage.slug === 'dolphin' ? <Dolphin width="250" height="120" /> : ''}

					<BannerTextHolder>
						<DolphinSeries>
							<span>Explore the</span>
							{series === 'M Line' ? <Mline /> : ''}
							{series === 'S Line' ? <Sline /> : ''}
							{series === 'X Line' ? <Xline /> : ''}
							{series === 'Wave' ? <Wave /> : ''}
							{/* {series === 'Swash Line' ? <Swash /> : ''}
							{series === 'Liberty Line' ? <Liberty /> : ''}} */}
						</DolphinSeries>
						{seriesPage.banner != null && series != 'Ozone Swim Line' ? (
							documentToReactComponents(JSON.parse(seriesPage.banner.description.raw), options)
						) : series === 'Ozone Swim Line' ? (
							<BannerText>Enjoy an easy and relaxing pool cleaning experience</BannerText>
						) : (
							''
						)}
					</BannerTextHolder>
				</DolphinBannerContainer>
			</DolphinBanner>
			<SeriesItemsContainer style={{ margin: products.length < 1 ? '0' : '' }}>
				{products.length < 1 ? (
					<NoItems>There is no other items for this line</NoItems>
				) : (
					products.map((items: Product, i: number) => {
						const { node: item } = items
						return (
							<ItemContainer key={i}>
								<SeriesItemImage>
									<source srcSet={`${item.images[0].fluid.srcWebp} 200w`} type="image/webp"></source>
									<source srcSet={item.images[0].fluid.src}></source>
									<img src={item.images[0].fluid.src} alt={`${item.brand} ${item.model}`} />
								</SeriesItemImage>
								{/* <SeriesItemImage
									src={item.images[0].fluid.srcWebp}
									alt={`${item.brand} ${item.model}`}
								></SeriesItemImage> */}
								<ItemLink to={`/product/${item.slug}`}>
									<ItemTtl>{item.model}</ItemTtl>
									<Triangle fill="#bababa" stroke="#bababa" height="15" width="15" viewBox="0 0 30 30" />
								</ItemLink>
							</ItemContainer>
						)
					})
				)}
			</SeriesItemsContainer>
			<CompareModels href="/product-comparison">Compare Products</CompareModels>
		</FullSectionCol>
	) : (
		<></>
	)
}

export default Series
